// Node Modules
import * as React from 'react';

// Components
import JoinUsFormWrapper from '../components/form/presentational/JoinUsFormWrapper';
import Layout from '../components/core/layout';
import OurNetworkHero from '../components/content-sections/our-network/OurNetworkHero';
import SEO from '../components/core/seo';

const OurNetworkPage = () => (
  <Layout>
    <SEO title="Our Network" />
    <OurNetworkHero />
    <JoinUsFormWrapper hideCourses />
  </Layout>
);

export default OurNetworkPage;
