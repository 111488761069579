// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import ContentOverImage from '../../resuables/ContentOverImage';
import YellowButton from '../../resuables/YellowButton';

// Styles
import { above, colors, space } from '../../../style/design-system';

// Utils
import { scrollToJoinUsForm } from '../../../util/functions/scrollToJoinUsForm';

const OurNetworkHero = ({ className }) => {
  const query = useStaticQuery(graphql`{
    heroImage: file(relativePath: {eq: "our-network/our-network-hero.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 1920)
      }
    }
  }`);

  return (
    <div className={className}>
      <ContentOverImage
        alt="A collection of pins connected by strings"
        dripHeight="200px"
        dripColor="#fff"
        dripLeft="200px"
        includeDrip
        gatsbyImage={query.heroImage.childImageSharp.gatsbyImageData}
        renderContent={() => (
          <div className="content d-flex ai-c">
            <div className="container">
              <div className="row">
                <div className="col-10 offset-1 col-sm-10 col-md-8 offset-md-4 col-lg-6 offset-lg-5 col-xl-5 offset-xl-6">
                  <div className="white-box">
                    <p className="my-0">We’ve been creating healthcare education and career pathways for our students for more than 27 years. Together with our employer partners, we’re working to help meet the urgent need for trained healthcare workers by placing knowledgeable graduates into allied health&nbsp;positions.</p>
                  </div>
                  <div className="d-flex jc-fe">
                    <YellowButton handleClickInParent={scrollToJoinUsForm}>
                      Join Our Network
                    </YellowButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} />
    </div>
  );
};

export default styled(OurNetworkHero)`
  .content {
    transform: translateY(-3rem);
    ${above.md`
      height: 18rem;
      transform: translateY(0);
    `}
    ${above.lg`
      height: 24rem;
    `}
    ${above.xl`
      height: 28rem;
    `}
  }
  .white-box {
    background-color: #fff;
    border: 1px solid ${colors.umaBlack};
    padding: ${space.f};
  }
`;

OurNetworkHero.propTypes = {
  className: PropTypes.string.isRequired,
};
